import React from "react"
import { Link } from "gatsby"
import _ from "lodash"

import SectionTitle from "../layout/section-title"
import Thumbnail from "../thumbnail/thumbnail"
import Filterpill from "./filterpill"

class Filterbox extends React.Component {

    constructor(props){
        super(props)
        this.state = { pills:[], items:[], filtered:[] }
    }

    componentWillMount(){
        const { categories, items } = this.props
        let p = []

        categories.forEach((category) => {
            p.push({
                value:category.slug,
                title:category.title,
                selected:false
            })
        })

        this.setState({ pills:p, items:items, filtered:items })
    }

    toggle(){

        let p = this.state.pills
        let slugs = []
        let filtered = []

        p.forEach((pill) => {
            if(pill.selected)
                slugs.push(pill.value)
        })

        this.setState({pills:p})

        this.state.items.forEach((item) => {
            if(slugs.includes(item.category.slug))
                filtered.push(item)
        })

        if(filtered.length < 1)
            filtered = this.state.items


        this.setState({ pills:p, filtered:filtered })
    }

    render(){

        let first = this.state.filtered.slice(0, 4)
        let second = this.state.filtered.slice(5)
        let groups = _.chunk(second, 6)

        return(
            <section className={"container mx-auto md:px-8 lg:px-8 mb-8"}>
                <div className={"mx-auto text-center px-0 md:px-4 md:text-left lg:px-0"}>
                    <span className={"block mb-2 md:inline uppercase text-gravel text-lg font-formula-light tracking-widest px-4"}>filters</span>
                    {
                        this.state.pills.map((item, index) => {
                            return (
                                    <Filterpill 
                                        onChange={this.onChange} 
                                        title={item.title} 
                                        slug={item.slug} 
                                        selected={item.selected}
                                        onClick={ () => {
                                                item.selected ? item.selected = false : item.selected = true
                                                this.toggle()
                                            } 
                                        }
                                        key={`category-${index}`}  />
                                )
                        })
                    }
                </div>
                <section className={"mt-8"}>
                  <div className={"container mx-auto"}>
                    <div className={"flex flex-wrap"}>
                      {first[0] && <div className={"mx-auto w-full md:w-1/2 px-4"}>
                        <Link to={`/${this.props.locale}/articles/${first[0].slug}`}>
                          <Thumbnail 
                            title={first[0].title} 
                            image={first[0].image.file.url} 
                            description={first[0].short} 
                            height={340}
                          />
                        </Link>
                      </div>}

                      {first[1] && <div className={"mx-auto w-full md:w-1/2 px-4"}>
                        <Link to={`/${this.props.locale}/articles/${first[1].slug}`}>
                          <Thumbnail 
                            title={first[1].title} 
                            image={first[1].image.file.url} 
                            description={first[1].short} 
                            height={340}
                          />
                        </Link>
                      </div>}
                    </div>
                    <div className={"flex flex-wrap"}>
                      {first[2] && <div className={"mx-auto w-full md:w-1/2 px-4"}>
                      <Link to={`/${this.props.locale}/articles/${first[2].slug}`}>
                        <Thumbnail 
                            title={first[2].title} 
                            image={first[2].image.file.url} 
                            description={first[2].short} 
                            height={340}
                          />
                        </Link>
                      </div>}
                      {first[3] && <div className={"mx-auto w-full md:w-1/2 px-4"}>
                        <Link to={`/${this.props.locale}/articles/${first[3].slug}`}>
                            <Thumbnail 
                            title={first[3].title} 
                            image={first[3].image.file.url} 
                            description={first[3].short} 
                            height={340}
                          />
                        </Link>
                      </div>}
                    </div>
                  </div>
                </section>
                <div>
                    {
                        groups.map((group, index) => {
                            return (
                                <div key={`group-${index}`}>
                                     <SectionTitle title={ index === 0 ? this.props.title : ''} position={"center"} />
                                    <div className={"flex flex-wrap -mt-12"}>
                                        { 
                                            group.map((item, i) => {
                                                return(
                                                    <div className={"w-full sm:w-full md:w-1/2 lg:w-1/3 px-4 lg:p-4"} key={i}>
                                                        <Link to={`/${this.props.locale}/articles/${item.slug}`}>
                                                            <Thumbnail
                                                                title={item.title}
                                                                image={item.image.file.url}
                                                                description={item.short}
                                                            />
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        } 
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </section>
        )
    }

}


export default Filterbox
