import React from "react"

import HamburgerClose from "../../images/hamburger-close.svg"

class Filterpill extends React.Component {

    render(){
        return(
            <div onClick={this.props.onClick}
                className={`cursor-pointer border-2 border-gravel rounded-full inline-block px-8 py-1 mx-2 mb-4 text-left ${this.props.selected ? 'bg-gravel text-white' : 'bg-white text-gravel'}`}
            >
                <span className={"font-agrandir text-md"}>
                    {this.props.title}
                    {this.props.selected && <img style={{width:"12px"}} className={"inline ml-2 -mt-1"} src={HamburgerClose} alt="" />}
                </span>
            </div>
        )
    }

}

export default Filterpill
