import React from "react"
import Swiper from "swiper"

import "./slider.scss"
import RightArrow from "../../images/arrow-small-right.svg"
import LeftArrow from "../../images/arrow-small-left.svg"
import Slide from "./slide"

class SliderCarousel extends React.Component {

    constructor(props){
        super(props)
        this.swiper = null
    }

    componentDidMount(){
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            this.swiper = new Swiper ('.swiper-container', {
                loop: false,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                  nextEl: '.button-next',
                  prevEl: '.button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
            })
        }
        
    }

    render(){

        const { slides, locale } = this.props

        return(
            <div className={"swiper-container"}>
                <div className={"lg:hidden swiper-pagination"} style={{zIndex:8000, top:"290px", height:"10px"}}></div>
                <div className={"swiper-wrapper"}>
                    { 
                        slides.map((slide, index) => { 
                            return (
                                <div className={"swiper-slide"} key={`slide-${index}`} >
                                    <Slide 
                                        title={slide.title} 
                                        short={slide.short} 
                                        image={slide.image.file.url} 
                                        link={slide.link} 
                                        cta={slide.cta} 
                                        locale={locale}
                                    />
                                </div>
                            )
                        }) 
                    } 
                </div>
                <div className={"button-prev absolute py-4 px-4 hidden lg:block outline-none"} style={{zIndex:9001, width:'120px', height:'40px', top:"50%", left:'20px'}}>
                    <img src={LeftArrow} alt="prev" />
                </div>
                <div className={"button-next absolute py-4 px-4 hidden lg:block outline-none"} style={{zIndex:9000, width:'60px', height:'40px', top:"50%", right:'20px'}}>
                    <img src={RightArrow} alt="next" />
                </div>
            </div>
        )

    }
}

export default SliderCarousel