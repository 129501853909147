import React from "react"
import PropTypes from "prop-types"

import "./page-header.scss"

class PageHeader extends React.Component {

    render(){

        const { children, theme, bottom } = this.props

        return(
            <section className={`page-header page-header-theme-${theme} relative`}>
                <div className={`container mx-auto lg:px-8 ${bottom ? 'md:mb-16 lg:mb-32' : ''}`}>
                    {children}
                </div>
                <div className={"page-header-pattern"} />
            </section>
        )

    }

}

PageHeader.defaultProps = {
    theme:'reef',
    bottom:true
}

PageHeader.propTypes = {
    theme:PropTypes.string,
    bottom:PropTypes.bool
}

export default PageHeader