import React from "react"

import "./section-title.scss"

class SectionTitle extends React.Component{

    render(){

        const {title, position} = this.props

        let styles = `section-title mx-auto w-11/12 md:w-2/3 text-center py-20 relative`

        if(position === 'left')
            styles = `section-title w-full text-left py-12 relative`

        return(
            <div className={styles}>
                <h1 className={"font-agrandir text-3xl lg:text-4xl leading-none capitalize"}>{title}</h1>
                <div className={`section-title-background ${position}`}></div>
            </div>
        )
    }


}

export default SectionTitle