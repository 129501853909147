import React from "react"
import { Link } from "gatsby"
import _ from "lodash"

const Hyperlink = ({ locale, href, children }) => {
    return(
        _.includes(href,'://') ? <a href={href} target="_blank" rel="noopener noreferrer">{children}</a> : <Link to={`/${locale}${href}`}>{children}</Link>
    )
}

export default Hyperlink