import React from "react"
import PropTypes from "prop-types"

import PlayIcon from "../../images/slider-play-icon.svg"
import "./thumbnail.scss"

class Thumbnail extends React.Component{

    render(){
        
        const imgStyle = {
            backgroundImage: `url(${this.props.image})`,
            backgroundPosition:`center top`,
            backgroundRepeat:'no-repeat',
            height:`${(this.props.height || 220)}px`
        } 
        
        // const mImgStyle = {
        //     backgroundImage: `url(${this.props.image})`,
        //     backgroundPosition:`center center`,
        //     height:`100%`
        // } 

        const playIcon = this.props.video ? <img className={"play-icon"} src={PlayIcon} alt="" /> : ''

        let short = this.props.description

        if(short.length > 90) {
            short = short.substring(0,24) + "...";
        }

        return(
            <div>
                <div className={"hidden md:block thumbnail w-full mb-12 relative"}>
                    <div className={"image-holder overflow-hidden relative"} style={{height:`${(this.props.height || 220)}px`}}>
                        {playIcon}
                        <div className={"image"} style={imgStyle}></div>
                    </div>
                    <div className={"hover-background"} style={{height:`${(this.props.height || 220)}px `}}></div>
                    <div className={"mt-4"}>
                        <p className={"title font-agrandir text-lg " + (this.props.theme === "dark" ? "text-white" : "text-black")}>{this.props.title}</p>
                        <span className={"font-montserrat-regular description text-sm " + (this.props.theme === "dark" ? "text-gray-700" : "text-black")}>{this.props.description}</span>
                    </div>
                </div>
                <div className={"block md:hidden flex mb-10"}>
                    <div className={"flex-1 pr-2"} style={{backgroundImage:`url(${this.props.image})`, height:'100px', backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>
                        {/* <img src={this.props.image} /> */}
                    </div>
                    <div className={"flex-1 pl-2 leading-tight"}>
                        <p className={"title font-agrandir text-md " + (this.props.theme === "dark" ? "text-white" : "text-black")}>{this.props.title}</p>
                        { this.props.details ? <span className={"font-montserrat-regular description text-sm " + (this.props.theme === "dark" ? "text-gray-700" : "text-black")}>{short}</span> : '' }
                    </div>
                </div>
            </div>
            
        )
    }

}

Thumbnail.defaultProps = {
    theme:'light',
    type:'minimal',
    details:false
}

Thumbnail.propTypes = {
    theme: PropTypes.string,
    type: PropTypes.string,
    details: PropTypes.bool
}

export default Thumbnail