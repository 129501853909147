import React from "react"

import "./slider.scss"
import Button from "../button/button"
import Hyperlink from "../button/hyperlink"

class Slide extends React.Component{

    render(){

        const { title, short, image, cta, link, locale } = this.props //link

        const imgStyle = {
            backgroundImage: `url(${this.props.image})`,
            backgroundPosition:`center center`,
            zIndex:1
        }
        
        return(

            <div className={"slider-slide"}>
                <Hyperlink locale={locale} href={link} children={
                  <>
                    <div className={"hidden md:block"}>
                        <div className={'absolute flex w-3/4 leading-tight h-full items-center'} style={{zIndex:100}}>
                            <div className={"align-middle ml-8 px-4 lg:px-12"}>
                                <h1 className={"text-white font-agrandir md:text-3xl lg:text-4xl leading-none align-middle "}>{title}</h1>
                                <p className={"text-white montserrat-regular text-md mb-8 align-middle "}>{short}</p>
                                <Button label={cta} type={"solid"} />
                            </div>
                        </div>
                        <div className={'relative img-holder overflow-hidden bg-black'} style={{zIndex:10}}>
                            <img src={image} className={"w-full slider-image"} alt="" />
                        </div>
                    </div>
                    <div className={"block md:hidden"}>
                        <div className={"sm-img-holder"} style={imgStyle}></div>
                        <div className={"px-8 pt-8 pb-20 -mt-12 mx-4 swiper-slide-theme-coral text-center absolute"} style={{zIndex:100}}>
                            <h1 className={"text-white font-agrandir text-2xl leading-tight mb-4"}>{title}</h1>
                            <p className={"text-white montserrat-regular text-md mb-4 align-middle "}>{short}</p>
                            <Button label={cta} type={"underline"} theme={"light"} />
                        </div>
                    </div>
                  </>
                }/>
            </div>
        )
    }

}

export default Slide
