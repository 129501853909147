import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import _ from "lodash"
import Filterbox from "../../components/layout/filterbox"
import Layout from "../../components/layout/layout"
import PageTransition from "../../components/transition/page"
import PageHeader from "../../components/layout/page-header"
import SEO from "../../components/seo/seo"
import SliderCarousel from "../../components/carousel/slider"

class LifestylePage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
    this.state = {
      featured: null,
      articles: null,
    }
  }
  animate() {
    this.pageTransition.current.play()
  }
  render() {
    const { path, data } = this.props
    const locale = "en-AU"
    const { slider, articles } = data
    let items = []
    let categories = []
    articles.articles.forEach(item => {
      items.push(item.article)
      categories.push({
        title: item.article.category.title,
        slug: item.article.category.slug,
      })
    })
    categories = _.uniqWith(categories, _.isEqual)
    return (
      <Layout locale={locale} theme="light" path={path} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={data.seo.title}
          description={data.seo.description}
          keywords={data.seo.keywords && data.seo.keywords.keywords}
          image={data.seo.image.file.url}
        />
        <PageHeader theme={"coral"} bottom={true}>
          <SliderCarousel slides={slider.slides} locale={locale} />
        </PageHeader>
        <Filterbox categories={categories} items={items} locale={locale} />
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const query = graphql`
  query LifestylePageQuery {
    seo: contentfulSeo(slug: {eq: "lifestyle"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    slider: contentfulSlider(slug: { eq: "lifestyle" }, node_locale: { eq: "en-AU" }) {
      slides {
        ... on ContentfulSlide {
          title
          short
          image {
            file {
              url
            }
          }
          link
          cta
        }
      }
    }
    articles: allContentfulArticle(
      limit: 50
      filter: { visible: { eq: true }, node_locale: { eq: "en-AU" }, category: { slug: { ne: "surfing" } } }
    ) {
      articles: edges {
        article: node {
          title
          short
          slug
          category {
            ... on ContentfulCategory {
              slug
              title
            }
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default LifestylePage
